import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { news } from "../../constants";
import "./News.scss";

function News() {
  return (
    <Layout>
      <main className="main">
        <h2 className="mb-2 w-100">Новости</h2>
        <p className="p-0 mb-4 w-100">
          В разделе представлены актуальные новости платформы, а также перечень
          последних обновлений
        </p>
        <div className="grid-container">
          {news.map((i) => (
            <Link to={`/news/${i.id}`} className="grid-item nolink" key={i.id}>
              <div className="d-flex flex-column">
                <div
                  style={{ flex: "0 0 10%" }}
                  className="p-1 overflow-hidden d-flex justify-content-between"
                >
                  <div>{i.data}</div>
                  <div>Подробнее →</div>
                </div>
                <div
                  style={{ flex: "0 0 70%" }}
                  className="p-1 overflow-hidden d-flex justify-content-center"
                >
                  <div
                    style={{
                      width: "300px",
                      height: "230px",
                      backgroundImage: `url(${i.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "contain",
                    }}
                  ></div>
                </div>
                <div
                  style={{ flex: "0 0 20%" }}
                  className="p-1 overflow-hidden"
                >
                  {i.title}
                </div>
              </div>
              {/* <p className="">{i.data}</p>
              
              <div className="">
                <img
                  className="news__item-image"
                  src={i.image}
                  alt={i.alt}
                />
                <h6 className="">{i.title}</h6>
              </div> */}
            </Link>
          ))}
        </div>
      </main>
    </Layout>
  );
}

export default News;
