import React from "react";
import StandardExtensionsDescription from "../../../components/standardExtensionsDescription/StandardExtensionsDescription";
import img1 from '../../../images/standardExtensions/formBuilderPlugin/formImg1.png'
import img2 from '../../../images/standardExtensions/formBuilderPlugin/formImg2.png'



const images = [
  {
    type: "image",
    img: img1,
  },
  {
    type: "image",
    img: img2,
  },
];

const description = [
  "Форма — раздел документа, позволяющий пользователю вводить информацию для последующей обработки системой. Плагин позволяет пользователю создавать собственные формы для задач опросных листов, авторизации и др.",
];

function FormBuilderPlugin() {
  const route = window.location.pathname;
  return (
    <StandardExtensionsDescription
      route={route}
      title={"formbuilder-plugin"}
      titleRu={"Создание форм"}
      description={description}
      images={images}
    />
  );
}

export default FormBuilderPlugin;
