import React from "react";
import { AppRoute } from "../../../constants";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import eJournalImg1 from "../../../images/technicalSolutions/eJournal1.png";
import eJournalImg2 from "../../../images/technicalSolutions/eJournal2.png";
import eJournalImg3 from "../../../images/technicalSolutions/eJournal3.png";
import eJournalImg4 from "../../../images/technicalSolutions/eJournal4.png";
import eJournalImg5 from "../../../images/technicalSolutions/eJournal5.png";
import eJournalImg6 from "../../../images/technicalSolutions/eJournal6.png";
import eJournalImg7 from "../../../images/technicalSolutions/eJournal7.png";
import eJournalImg8 from "../../../images/technicalSolutions/eJournal8.png";
import eJournalImg9 from "../../../images/technicalSolutions/eJournal9.png";
import eJournalImg10 from "../../../images/technicalSolutions/eJournal10.png";


const images = [
  {
    title: "Пример реализации оперативного журнала",
    img: eJournalImg1,
    type: "image",
  },
  {
    title: "Пример реализации записи оперативного журнала",
    img: eJournalImg2,
    type: "image",
  },
  {
    title: "Пример реализации журнала работ по нарядам-допускам и распоряжениям",
    img: eJournalImg3,
    type: "image",
  },
  {
    title: "Пример реализации задания на работы по наряду",
    img: eJournalImg4,
    type: "image",
  },
  {
    title: "Пример реализации журнала диспетчерских и оперативных заявок",
    img: eJournalImg5,
    type: "image",
  },
  {
    title: "Пример реализации заявки на выполнение работ",
    img: eJournalImg6,
    type: "image",
  },
  {
    title: "Пример журнала отказов на электрооборудовании",
    img: eJournalImg7,
    type: "image",
  },
  {
    title: "Пример заполнения формы об отказе на оборудовании",
    img: eJournalImg8,
    type: "image",
  },
  {
    title: "Пример реализации настройки плана переключений для выключателя",
    img: eJournalImg9,
    type: "image",
  },
  {
    title: "Пример реализации данных о персонале",
    img: eJournalImg10,
    type: "image",
  },
];

const infoStructure = [
  {
    title: "Общая информация",
    description:
      "Решение по ведению единого автоматизированного электронного журнала с привязкой к объектам модели электроснабжения предприятия выполнено на базе стандарта CIM (МЭК 61968-11, МЭК 61970-301, МЭК 61970-452) в соответствии со следующей нормативно-технической документацией:",
    points: [
      {
        id: 1,
        title:
          "Постановление Правительства Российской Федерации от 13 августа 2018 г. N 937 «Об утверждении правил технологического функционирования электроэнергетических систем и о внесении изменений в некоторые акты Правительства Российской Федерации»",
      },
      {
        id: 2,
        title:
          "Приказ Министерства Энергетики Российской Федерации от 13 сентября 2018 года N 757 Об утверждении Правил переключений в электроустановках (с изменениями на 4 октября 2022 года)",
      },
      {
        id: 3,
        title:
          "Приложение N 1 к письму Главного управления государственного энергетического надзора от 9 ноября 1995 года N 42-6/35-ЭТ «О ведении оперативного журнала»",
      },
      {
        id: 4,
        title:
          "Правила по охране труда при эксплуатации электроустановок, утвержденным приказом Минтруда России от 15.12.2020 N 903н (ПОТЭУ)",
      },
      {
        id: 5,
        title:
          "Правила технической эксплуатации электроустановок потребителей (ПТЭЭП) утвержденные приказом Минэнерго России от 12.08.2022 №811",
      },
      {
        id: 6,
        title:
          "Приложение 8 к Правилам по охране труда при эксплуатации электроустановок, утвержденных приказом Минтруда России от 15.12.2020 № 903н, раздел 6.6",
      },
    ],
  },
  {
    title: "Назначение",
    description:
      "Электронный журнал представляет собой многофункциональный инструмент, предназначенный для следующих категорий сотрудников:",
    articles: [
      {
        id: 1,
        title:
          "Оперативного персонала предприятий электроэнергетики и других отраслей промышленности для ведения и учета оперативных данных",
      },
      {
        id: 2,
        title:
          "Операторов диспетчерских служб для быстрого реагирования на аварийные ситуации",
      },
      {
        id: 3,
        title:
          "Инженеров и технических специалистов для мониторинга состояния сети и оборудования",
      },
      {
        id: 4,
        title:
          "Персонала, ответственного за техническое обслуживание, для планирования ремонтов, профилактических мероприятий и сопутствующих им переключений",
      },
      {
        id: 5,
        title:
          "Аналитиков для выявления тенденций в работе оборудования и подготовки отчетов",
      },
      {
        id: 6,
        title:
          "Руководителей для принятия стратегических решений на основе анализа данных",
      },
    ],
  },
  {
    title: "Виды",
    description: "Электронный журнал включает в себя следующие виды журналов:",
    articles: [
      {
        id: 1,
        title:
          "единый оперативный журнал для всех служб промышленного предприятия",
      },
      {
        id: 2,
        title:
          "журнал учета выявленных дефектов и неполадок на электрооборудовании",
      },
      {
        id: 3,
        title: "журнал учета бланков переключений",
      },
      {
        id: 4,
        title: "журнал учета работ по нарядам и распоряжениям",
      },
      {
        id: 5,
        title: "журнал диспетчерских заявок по электросетевому оборудованию",
      },
      {
        id: 6,
        title:
          "журнал отчета об отключениях электрооборудования с выводом расчета показателей надежности электроснабжения SAIDI, SAIFI",
      },
      {
        id: 7,
        title: "журнал отклонений от нормальной схемы",
      },
    ],
  },
  {
    title: "Основные функции",
    description: "Решение по Электронному журналу позволяет выполнять следующие основные функции:",
    points: [
      {
        id: 1,
        title: "Учёт переключений на оборудовании",
        description: [
          "Ведение данных по переключениям на объектах электроснабжения",
          "Ведение данных по заявкам на вывод оборудования, в том числе, для подачи в АО «СО ЕЭС»",
          "Ведение данных по бланкам переключений",
        ],
      },
      {
        id: 2,
        title: "Ведение данных по смене и проведенным работам",
        description: [
          "Ведение данных о начале и завершении рабочей смены оперативного персонала на объектах электроснабжения",
          "Ведение и контроль данных о работах по нарядам",
          "Ведение и контроль данных о работах по распоряжениям",
          "Ведение данных о персонале (группа по электробезопасности, роль в бригаде, разрешения на работу, допуски к самостоятельному обслуживанию и т.п.)",
          "Ведение данных о технических мероприятиях по обеспечению безопасности работ",
        ],
      },
      {
        id: 3,
        title: "Мониторинг состояния оборудования",
        description: [
          "Ведение данных по отключению и изменению режима работы оборудования на объектах электроснабжения",
          "Ведение данных об отказах, дефектах и авариях на оборудовании",
          "Ведение данных об отказах программных и аппаратных средств автоматизации",
        ],
      },
      {
        id: 4,
        title: "Анализ и повышение надежности электроснабжения",
        description: [
          "Анализ отключений электроэнергии по различным объектам и абонентам (расчет SAIDI - средней продолжительности отключений и SAIFI – средней частоты отключений)",
          "Формирование предложений по оптимизация сети и распределения нагрузки для предотвращения перегрузок и частых отключений",
        ],
      },
      {
        id: 5,
        title: "Управление инцидентами",
        description: [
          "Регистрация и отслеживание инцидентов, связанных с электроснабжением (аварии, отключения, срабатывания систем автоматики)",
          "Анализ причин инцидентов и формирование рекомендаций по их предотвращению",
        ],
      },
      {
        id: 6,
        title: "Коммуникация и уведомления",
        description: [
          "Уведомление сотрудников, ответственных за электроснабжение при появлении новых записей об отключениях, авариях, дефектах",
          "Регистрация уведомлений от смежных предприятий",
          "Регистрация обращений внешних субъектов",
        ],
      },
      {
        id: 7,
        title: "Анализ и отчётность",
        description: [
          "Генерация аналитических отчётов по различным событиям и инцидентам",
          "Возможность анализа статистических данных за разные временные промежутки",
        ],
      },
      {
        id: 8,
        title: "Интеграция с другими системами",
        description: [
          "Подключение к системам автоматизации управления производственными процессами (SCADA) для автоматизации ведения данных по переключениям",
          "Интеграция с системами учёта для оценки перегрузки участков сети и тяжести аварий",
        ],
      },
    ],
  },
];

function EJournal() {
  return (
    <TechnicalSolutionsContent
      route={window.location.pathname}
      images={images}
      noGost={true}
      title={
        "Техническое решение по ведению единого автоматизированного электронного журнала с привязкой к объектам модели электроснабжения предприятия"
      }
      infoStructure={infoStructure}
    />
  );
}

export default EJournal;
