function ArticleVersion({points, image}) {
  return (
    <div className="mt-4 mb-4 d-flex flex-column w-100">
      <ul className="article__list w-100">
        {points.map((li) => (
          <li key={li} className="mb-2">{li}</li>
        ))}
      </ul>
    </div>
  );
}

export default ArticleVersion;
