import React from "react";
import { Link } from "react-router-dom";
import GOST from "../gost/GOST";
import Layout from "../Layout/Layout";
import { AppRoute, technicalSolutionsLinks } from "../../constants";
import "./TechnicalSolutionsContent.scss";
import TechnicalSolutionSlider from "../technicalSolutionSlider/TechnicalSolutionSlider";
import PrevAndNextButtons from "../../components/prevAndNextButtons/PrevAndNextButtons";

function TechnicalSolutionsContent({
  extensions,
  functions,
  title,
  description,
  sliderTitle,
  images,
  infoStructure,
  noGost,
  centerHeight,
  specificationsTitle,
  specifications,
  video,
  route,
}) {
  const renderPoints = (isDot, points) => {
    return (
      <ul className="mb-4 description__list">
        {points.map((i) => {
          return (
            <li
              className={
                isDot
                  ? "mb-1 description__circle-li"
                  : "mb-1 description__number-li"
              }
              key={i.title}
            >
              {i.title}
              {i.description && (
                <ol>
                  {i.description.map((e) => (
                    <li
                      className="mb-2 mt-2 description__circle-li"
                      style={{ fontSize: "0.95rem" }}
                      key={e}
                    >
                      {e}
                    </li>
                  ))}
                </ol>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Layout>
      <main className="main">
        {noGost ? null : <GOST />}
        <h1 className="mb-4 text-center">{title}</h1>
        <div className="align-start d-flex flex-column mb-4">
          {infoStructure &&
            infoStructure.map((element, index) => {
              return (
                <>
                  <h5 className="mb-3 mt-3">
                    {index + 1}. {element.title}
                  </h5>
                  <p>{element.description}</p>
                  {element.points && renderPoints(false, element.points)}
                  {element.articles && renderPoints(true, element.articles)}
                </>
              );
            })}
          {/* {specificationsTitle && <p>{specificationsTitle}</p>}
          {specifications && (
            <ul className="mb-4 description__list">
              {specifications.map((i) => (
                <li className="mb-1 description__number-li" key={i.title}>
                  {i.title}
                  {i.description && (
                    <ol>
                      {i.description.map((e) => (
                        <li className="mb-1 description__circle-li" key={e}>
                          {e}
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
            </ul>
          )} */}
          {description && <p>{description}</p> }
          {functions && <h5 className="mb-3 mt-3">Основные функции:</h5>}
          {functions &&
          <ul className="mb-4 description__list">
            {
              functions.map((i) => (
                <li className="mb-1 description__number-li" key={i.title}>
                  {i.title}
                  {i.description && (
                    <ol>
                      {i.description.map((e) => (
                        <li className="mb-1 description__circle-li" key={e}>
                          {e}
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
          </ul>}
          {extensions ? (
            <>
              <h5 className="mb-3 mt-3">
                Стандартные расширения, необходимые для реализации технического
                решения:
              </h5>
              <ul className="mb-4 description__list">
                {extensions.map((i) => (
                  <li className="mb-1 description__number-li" key={i.title}>
                    <Link to={i.link} className="text-links">
                      {i.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <h5 className="mb-3 mt-3">
              Зависимостей от стандартных расширений нет
            </h5>
          )}
          {sliderTitle && <h5 className="mb-3 mt-3">{sliderTitle}</h5>}
        </div>
        {images && (
          <TechnicalSolutionSlider
            images={images}
            centerHeight={centerHeight}
            video={video}
          />
        )}
        <PrevAndNextButtons
          buttonName="решению"
          route={route}
          links={technicalSolutionsLinks}
        />
        <Link to={AppRoute.MainPage}>
          <button
            className="not-found__button button-hover mt-4"
            type="button"
            aria-label="вернуться на главную страницу"
          >
            На Главную
          </button>
        </Link>
      </main>
    </Layout>
  );
}

export default TechnicalSolutionsContent;
