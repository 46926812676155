import React from "react";
import Article1 from "./components/Articles/Article1";
import Article2 from "./components/Articles/Article2";
import Article3 from "./components/Articles/Article3";
import Article4 from "./components/Articles/Article4";
import Article5 from "./components/Articles/Article5";
import Article6 from "./components/Articles/Article6";
import Article7 from "./components/Articles/Article7";
import Article8 from "./components/Articles/Article8";
import Article9 from "./components/Articles/Article9";
import ArticleVersion from "./components/Articles/ArticleVersion";
import mfes from "./images/News/mfes.jpg";
import StandartArticle from "./components/Articles/StandartArticle";
import tes from "./images/News/1_tes.png";
import Vn from "./images/News/2_Vn.png";
import minsvyazi from "./images/News/3_minsvyazi.png";
import release from "./images/News/newRelease1.png";
import rza from "./images/News/rza.jpg";
import cres from "./images/News/4_cres.png";
import gost from "./images/News/5_gost.jpg";
import CIM from "./images/News/6_CIM.png";
import Baikal from "./images/News/7_Baikal.png";
import film from "./images/News/8_film.png";
import rosseti from "./images/News/rosseti.png";

export const AppRoute = {
  MainPage: "/",
  NotFound: "*",
  News: "/news",
  Rosseti: "/rosseti",
  ACM: "/acm",
  Transneft: "/transneft",
  Lenenergo: "/lenenergo",
  RZA: "/rza",
  Partners: "/partners",
  NewsItem: "/news/:id",
  ElectricPower: "/electric-power",
  MiningIndustry: "/mining-industry",
  ManufacturingIndustry: "/manufacturing-industry",
  Production: "/production",
  TimeAttendanceSystem: "/time-and-attendance-system",
  Telecommunication: "/telecommunication-asets-accounting-system",
  EnergyFacilities: "/automation-system-for-designing-wind-energy-facilities",
  Build: "/build",
  Dm: "/dm",
  Docs: "/docs",
  Edu: "/edu",
  Eqgen: "/eqgen",
  Eqhv: "/eqhv",
  Eqmv: "/eqmv",
  Gis: "/gis",
  Maintanance: "/maintanance",
  Metering: "/metering",
  Opt: "/opt",
  Oscl: "/oscl",
  Pf: "/pf",
  Rpa: "/rpa",
  Sc: "/sc",
  Se: "/se",
  Uml: "/uml",
  DeveloperPlugin: "/developer-plugin",
  VectorPlugin: "/vector-plugin",
  FormBuilderPlugin: "/formbuilder-plugin",
  ScadaPlugin: "/scada-plugin",
  MessagePlugin: "/message-plugin",
  MapPlugin: "/map-plugin",
  OscilloscopePlugin: "/oscilloscope-plugin",
  VideoPlugin: "/video-plugin",
  DashboardPlugin: "/dashboard-plugin",
  TestProgramsAndMethods: "/test-programs-and-methods",
  TechnicalSupport: "/technical-support",
  TechnicalRequirements: "/technical-requirements",
  ScientificDevelopmentAndResearch: "/scientific-development-and-research",
  ProjectDocumentation: "/project-documentation",
  OperationalDocumentation: "/operational-documentation",
  Implementation: "/implementation",
  SurveyReport: "/survey-report",
  DemoForm: "/demoForm",
  EJournal: "/ejournal",
};

export const news = [
  {
    title: `Совместный доклад ГК "ЭнергопромАвтомтизация" и ООО НПП «ЭКРА» на заседании Научно-технического совета (НТС) ПАО «Россети» о комплексе ПТК «Эксплуатация» и его интеграции с модулями сторонних производителей`,
    data: "25.12.2024",
    id: 20,
    page: (
      <StandartArticle
        description={`Заседание научно-технического совета (НТС) ПАО «Россети» состоялось 24 декабря. Предметом обсуждения стал новый подход к расчету параметров срабатывания устройств релейной защиты (уставок).

О разработке модуля для пересчета индивидуальных уставок РЗА рассказал представитель компании «ЭКРА» Иван Кошельков.
Подробнее о комплексе [ПТК Эксплуатация](/rosseti) и его интеграции с модулями производителей рассказал представитель компании ГК «Энергопромавтоматизация» Дмитрий Доминевский. Дмитрий Доминевский продемонстрировал на специализированном макете ПТК «Эксплуатация» [ПТК Эксплуатация](/rosseti) для интеграции расчетных модулей, разработанных в соответствии с СТО 56947007-33.040.20.326-2023, весь процесс расчета обязательных параметров срабатывания, расчет индивидуальных параметров срабатывания с помощью модуля НПП «ЭКРА» и передачу файла в ПО «ЭКРАSMS» для возможности загрузки в терминал РЗА.

Ссылка на источник: https://eepir.ru/new/novyj-podhod-k-raschetu-parametrov-srabatyvaniya-rza/`}
      />
    ),
    image: rza,
  },
  {
    title:
      "На Международном Форуме «Электрические Сети - 2024» были представлены доклады о продуктах, разработанных на базе NPT Platform",
    data: "06.12.2024",
    id: 19,
    page: (
      <StandartArticle
        description={`В Москве в период с 03 по 05 декабря 2024 года в МВЦ «КРОКУС ЭКСПО» прошел Международный форум «Электрические сети» (МФЭС). За 26 лет работы выставка «Электрические сети России» и Международный форум «Электрические сети» стали центральной демонстрационной площадкой технических достижений и инновационного развития электросетевого комплекса России.

Мероприятие традиционно объединило специалистов электроэнергетической и электротехнической отраслей, научных экспертов, представителей органов государственной власти. Насыщенная программа форума включала деловые сессии и круглые столы, дискуссии, научно-практические конференции и выставку оборудования для электроэнергетической отрасли.

Специалисты ГК «ЭнергопромАвтоматизация» представили свои доклады сразу на двух научно-практических конференциях: «Автоматизация и информационные технологии в энергетике» и «Релейная защита и автоматизация энергосистем. Совершенствование эксплуатации и перспективы развития».

С докладом «Интеграция в [ПТК Эксплуатация](/rosseti) модулей сторонних производителей с использованием микросервисной архитектуры» выступил технический директор НТЦ «ЭнергопромАвтоматизация» О.В. Кириенко;
Доклад на тему «Автоматизированная система мониторинга устройств релейной защиты и автоматики [( АСМ РЗА )](/acm) на базе ПО RPA Monitor и NPT Platform» представил руководитель отдела релейной защиты и противоаварийной автоматики ГК «ЭнергопромАвтоматизация» Д.К. Доминевский.`}
      />
    ),
    image: mfes,
  },
  {
    title: "Обновление платформы - версия 2.2.9",
    data: "06.09.2024 17:10",
    id: 18,
    page: (
      <ArticleVersion
        points={[
          "Исправлена ошибка в таблице c пагинацией при переходе на последнюю страницу",
          "Backport 2.3: Реализована поддержка хранимых асинхронных задач",
          "Backport 2.3: Сохранение SecurityContext (пользователя) в асинхронных задачах",
          "Добавлена проверка необходимости переиндексации ветвей для уменьшения количества транзакций",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.2.7",
    data: "17.05.2024 17:11",
    id: 17,
    page: (
      <ArticleVersion
        points={[
          "Исправлено масштабирование карты",
          "Исправлена ошибка при редактировании даты в календаре",
          "Добавлена сортировка пунктов контекстного меню",
          "Добавлен запрос на подтверждение перезагрузки таблицы после добавления динамической колонки",
          "Добавлено масштабирование диаграммы Ганта при изменении ширины страницы",
          'Исправлено перекрытие выпадающего списка кнопкой "ИЛИ" в таблице"',
          "Исправлено редактирование вложенных компаундов",
          "Добавлена возможность регулировать ширину панелей компонента splitter",
          "Добавлена функция для форматирования даты и времени в отчетах",
          "Исправлена ошибка с рассинхроном скроллинга заголовка и тела диаграммы Ганта при обновлении данных таблицы",
          "Исправлена фильтрация по динамическим колонкам",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.2.6",
    data: "09.02.2024 17:19",
    id: 16,
    page: (
      <ArticleVersion
        points={[
          "Добавлена возможность автоматической генерации модели таблицы по данным запроса к субъектам",
          "Удалена маска из календаря",
          "Добавлена возможность автоматической генерации динамических колонок из модели таблицы",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.2.5",
    data: "02.02.2024 17:16",
    id: 15,
    page: (
      <ArticleVersion
        points={[
          "Добавлена возможность выполнять join с дочерними и родительскими объектами перечислений",
          "Добавлена возможность указывать способ связи с родительским уровнем в деревьях",
          "Добавлена возможность автоматичеcкой генерации ветвей для enumeration",
          "Реализована возможность добавлять/удалять индексы БД",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.2.3",
    data: "27.10.2023 17:18",
    id: 14,
    page: (
      <ArticleVersion
        points={[
          "Исправлена ошибка отображения времени в осциллографе",
          "Исправлены цвета в finder таблицы для темной темы",
          "Исправлены цвета в контекстном меню элемента и соединительной линии в vector plugin для темной темы",
          "Исправлена ошибка с отсутствием кэширования информации о предикатах в RDFModelAdapter, которая приводила к отправке большого количества запросов к БД в SPARQL запросах",
          "Удалена зависимость jaxb-runtime",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.2.2",
    data: "06.10.2023 17:30",
    id: 13,
    page: (
      <ArticleVersion
        points={[
          "Исправлена ошибка с неверным расположением пинов и обмоток в трансформаторе",
          "Классам напряжений 20 и 27 установлены цвета",
          "Исправлена горизонтальная прокрутка в осциллографе",
          "Исправлена работа переноса узла в редакторе подстанций",
          "Добавлена панель элементов для редактора схем",
          "Добавлен инструмент для привязки сигналов в редакторе схем",
          "Исправлена ошибка, при которой не работали права доступа для кнопок в платформе",
          "Исправлена ошибка загрузки предыдущих записей истории субъекта",
          "В таблицу платформы добавлена возможность отображения всех записей сразу. Используется в истории субъекта",
          "В редакторе схем добавлена анимация течения тока по соединительным линиям",
        ]}
      />
    ),
    image: release,
  },
  {
    title:
      "Представляем вашему вниманию фильм, созданный на основе демонстрации жизненного цикла РЗА с помощью программных комплексов: САПР «ЭК РЗА и АСУ ТП»,  ПТК «Эксплуатация» и ПТК «Приемка» с детальным показом всех этапов работы на примере создания ПС. ",
    data: "06.10.2023 18:11",
    page: <Article8 />,
    id: 12,
    image: film,
    alt: "изображение новости про фильм РЗА",
  },
  {
    title: "Обновление платформы - версия 2.2.1",
    data: "04.08.2023 17:00",
    id: 11,
    page: (
      <ArticleVersion
        points={[
          "Реализован DifferenceModelAdapter для сравнения информационных моделей",
          "Добавлены новые обучающие материалы по разработке: по созданию продвинутых отображений и поиску по графам",
          "Реализован новый механизм построение истории по субъектам",
          "Переработаны модальные окна типа 'view'",
        ]}
      />
    ),
    image: release,
  },
  {
    title: "Обновление платформы - версия 2.1.3",
    data: "30.06.2023 17:14",
    id: 10,
    page: (
      <ArticleVersion
        points={[
          "Реализована генерация контрольной суммы для WAR файла во время сборки",
        ]}
      />
    ),
    image: release,
  },
  {
    title:
      "ПТК «Эксплуатация» - отечественная система, реализованная на базе NPT Platform, включена в Единый реестр российских программ для электронных вычислительных машин и баз данных Минкомсвязи РФ",
    data: "28.06.2023 17:51",
    page: <Article9 />,
    id: 9,
    image: rosseti,
    alt: "изображение новости ПТК «Эксплуатация»",
  },
  {
    title: "Обновление платформы - версия 2.1.1",
    data: "09.06.2023 17:10",
    id: 8,
    page: (
      <ArticleVersion
        points={[
          "Завершена разработка основных концепций второй платформы: выполнена подготовка к релизу",
        ]}
      />
    ),
    image: release,
  },
  {
    title:
      "NPT Platform успешно прошла испытания на совместимость с российской операционной системой «Альт рабочая станция» и аппаратной платформой на базе процессора Baikal-M",
    data: "30.05.2022 13:09",
    page: <Article7 />,
    id: 7,
    image: Baikal,
    alt: "изображение новости NPT Platform успешно прошла испытания",
  },
  {
    title: "ГК «ЭнергопромАвтоматизация» на конференции «CIM в России и мире»",
    data: "14.02.2022 20:40",
    page: <Article6 />,
    id: 6,
    image: CIM,
    alt: "изображение новости ГК «ЭнергопромАвтоматизация» на конференции «CIM в России и мире»",
  },
  {
    title:
      "Начато публичное обсуждение проектов ГОСТ Р на профиль информационной модели устройств релейной защиты и автоматики",
    data: "04.02.2022 14:37",
    page: <Article5 />,
    id: 5,
    image: gost,
    alt: "изображение новости проекты ГОСТ",
    article: [
      {
        id: 1,
        type: "p",
        description: [
          "Первая редакция проекта национального стандарта ГОСТ Р «Единая энергетическая система и изолированно работающие энергосистемы. Информационная модель электроэнергетики. Профиль информационной модели устройств релейной защиты и автоматики», разработанная Департаментом релейной защиты, метрологии и автоматизированных систем управления технологическими процессами ПАО «Россети» и ГК «ЭнергопромАвтоматизация», представлена для публичного обсуждения в Технический комитет по стандартизации «ЭЛЕКТРОЭНЕРГЕТИКА» (ТК 016).",
          "Проект стандарта разработан по плану работ подкомитета ПК-7 «Интеллектуальные технологии в электроэнергетике» и предназначен для обмена информацией по устройствам РЗА в формате общей информационной модели CIM между субъектами электроэнергетики, потребителями электрической энергии, проектными и научными организациями.  CIM модель обеспечивает однозначную интерпретацию передаваемых и получаемых данных всеми участниками информационного обмена.",
        ],
      },
    ],
  },

  {
    title:
      "Поздравляем ПАО «Россети Ленэнерго» с получением диплома конкурса «Сделано в России» за создание электронного каталога типовых решений для построения высокоавтоматизированных районов электрических сетей (РЭС)!",
    data: "27.12.2021 18:23",
    page: <Article4 />,
    id: 4,
    image: cres,
    alt: "изображение новости ПАО «Россети Ленэнерго» получила диплом «Сделано в России»",
  },
  {
    title:
      'Программное обеспечение ГK "ЭнергопромАвтоматизация" внесено в единый реестр российских программ для электронных вычислительных машин и баз данных Минкомсвязи России',
    data: "25.12.2020 13:46",
    page: <Article3 />,
    id: 3,
    image: minsvyazi,
    alt: 'изображение новости Программное обеспечение ГK "ЭнергопромАвтоматизация" внесено в единый реестр российских программ',
  },
  {
    title:
      "ГК «ЭнергопромАвтоматизация» в составе консорциума разработала корпоративную систему ПТК «Эксплуатация»",
    data: "26.10.2020 15:11",
    page: <Article2 />,
    image: Vn,
    id: 2,
    alt: "изображение новости ГК «ЭнергопромАвтоматизация» в составе консорциума разработала корпоративную систему ПТК «Эксплуатация»",
  },
  {
    title:
      "ООО «ЭнергопромАвтоматизация» разрабатывает корпоративную информационную систему для ПАО «Транснефть»",
    data: "18.10.2016 12:07",
    image: tes,
    alt: "ООО «ЭнергопромАвтоматизация» разрабатывает корпоративную информационную систему для ПАО «Транснефть»",
    id: 1,
    page: <Article1 />,
  },
];

export const code = `
<table xmlns="http://npt-platform.com/table" id="substationTable">
  <source>
    <finder classList="cim:Substation" pageable="true">      
    </finder>
  </source>
  <header>
      <column label="Административно-территориальная единица" field="region" path="cim:Substation.Region.cim.IdentifiedObject.name" filter-strategy="string"></column>
      <column label="Наименование ПС" field="label" path="cim:IdentifiedObject.name" filter-strategy="string"></column>
      <column label="Распределительные устройства" field="voltageLevel" path="cim:Substation.VoltageLevels.cim.IdentifiedObject.name" filter-strategy="string"></column>
  </header>
</table>
`;

export const code2 = `
  var query = require('query');
  var table = require('table');

  table.select(function(request) {
    var qb = query.instanceOf('cim:Substation')
    .join("voltageLevel", "cim:Substation.VoltageLevels")
    .equal('voltageLevel[cim.IdentifiedObject.name]', 'ОРУ 500 кВ')  
    return qb;
  });
`;

export const code3 = `
<tree xmlns="http://npt-platform.com/tree" id="registry">
  <branch id="GR" select="cim:GeographicalRegion" icon="npt-i-electrical-unit" icon-color="#418FDE">      
    <add id="addSGR" class="cim:SubGeographicalRegion" parent-ref="cim:SubGeographicalRegion.Region"/>
    <branch id="SGR" select="cim:SubGeographicalRegion" icon="npt-i-eg-alter" icon-color="#FFBE33">
      <add id="addSubstation" class="cim:Substation" parent-ref="cim:Substation.Region"/>      
      <branch id="Substation" select="cim:Substation" icon="npt-i-ps" icon-color="#228B22"></branch> 
    </branch> 
  </branch> 
</tree>
`;

export const code4 = `
"toolbar": [
  {
    "id": "export",
    "type": "group",
    "label": "Экспорт",     
    "group": [
      {
        "id": "exportSubjectsProfile",
        "label": "Экспорт профиля субъектов ПС"
      },
      {
        "id": "exportSubjectsModel",
        "label": "Экспорт субъектов ПС"
      }            
    ]
  },        
  {
    "id": "Reports",
    "type": "button",
    "label": "Отчеты"
  }
]
`;

export const code5 = `
"content": [
  {
    "ui": "grid-cell",
    "content": [
      {
      "ui": "predicate-placeholder",
      "ref": "cim:IdentifiedObject.name"
      },
      {
      "ui": "predicate-placeholder",
      "ref": "cim:IdentifiedObject.mRID",
      "options": {
        "label": "Идентификатор в мастер системе"
        }
      },
      {
        "ui": "predicate-placeholder",
        "ref": "cim:Substation.Region"
      }
    ]
  }
]
`;

export const code6 = `
var query = require('query');
var model = require('model');

model.build('substationModel', 'rdf/xml', function() {
  return model
    .over(query.instanceOf(
    'cim:Substation', 
    'cim:PowerSystemResource', 
    'cim:TransformerEnd'
  ) .primitives(true)
    .subjectRelations(true)
});
`;

export const code7 = `
var files = require('files');
var printRDF = require('print').printRDF;

var adapter = files.loadRDFModel('model.rdf');
var profile = files.loadXSDProfile('profile.xsd');
adapter.setProfile(profile);

printRDF(adapter);
`;

export const technicalSolutionsLinks = [
  {
    title:
      "Ведение проектов строительства и реконструкции объектов ветроэнергетики",
    link: AppRoute.Build,
  },
  {
    title: "Витрина данных реального времени",
    link: AppRoute.Dm,
  },
  {
    title: "Ведение НТД",
    link: AppRoute.Docs,
  },
  {
    title: "Размещение обучающих видеоматериалов",
    link: AppRoute.Edu,
  },
  {
    title: "Ведение реестра генерирующего оборудования",
    link: AppRoute.Eqgen,
  },
  {
    title:
      "Ведение реестра ЛЭП и электросетевого оборудования напряжением 110—750 кВ",
    link: AppRoute.Eqhv,
  },
  {
    title:
      "Ведение реестра ЛЭП и электросетевого оборудования напряжением 0,4—35 кВ",
    link: AppRoute.Eqmv,
  },
  {
    title: "Отображение объектов на ГИС",
    link: AppRoute.Gis,
  },
  {
    title: "Управление мероприятиями ТОиР первичного оборудования",
    link: AppRoute.Maintanance,
  },
  {
    title: "Ведение единого автоматизированного электронного журнала",
    link: AppRoute.EJournal,
  },
  {
    title:
      "Введение учета потребления электроэнергии и оценки энергоэффективности",
    link: AppRoute.Metering,
  },
  {
    title: "Риск-ориентированная система учета телекоммуникационных активов",
    link: AppRoute.Opt,
  },
  {
    title: "Отображение осциллограмм",
    link: AppRoute.Oscl,
  },
  {
    title: "Расчет параметров установившегося и послеаварийного режимов",
    link: AppRoute.Pf,
  },
  {
    title: "АСМ РЗА",
    link: AppRoute.Rpa,
  },
  {
    title: "Расчет ТКЗ",
    link: AppRoute.Sc,
  },
  {
    title: "Оценивание состояния установившегося режима сети",
    link: AppRoute.Se,
  },
  {
    title:
      "Создание диаграмм классов информационной модели с использованием UML",
    link: AppRoute.Uml,
  },
];

technicalSolutionsLinks.sort((a, b) => a.title.localeCompare(b.title));

export const implementationExamples = [
  {
    title: "ПТК «Эксплуатация» (ПАО «Россети»)",
    link: AppRoute.Rosseti,
  },
  {
    title: "АСМ РЗА",
    link: AppRoute.ACM,
  },
  {
    title: "КИАС ДЭО (ПАО «Транснефть»)",
    link: AppRoute.Transneft,
  },
  {
    title: "ЭК для ЦРЭС (ПАО «Ленэнерго»)",
    link: AppRoute.Lenenergo,
  },
  {
    title: "Сбор и анализ аварийной информации РЗА",
    link: AppRoute.RZA,
  },
  {
    title: 'ПТК "Производство"',
    link: AppRoute.Production,
  },
  {
    title: "Система учета рабочего времени",
    link: AppRoute.TimeAttendanceSystem,
  },
  {
    title: "Система учета телекоммуникационных активов",
    link: AppRoute.Telecommunication,
  },
  {
    title: "Система автоматизации проектирования объектов ветроэнергетики",
    link: AppRoute.EnergyFacilities,
  },
];

implementationExamples.sort((a, b) => a.title.localeCompare(b.title));

export const pluginsLinks = [
  {
    title: "Инструменты разработки платформы (developer-plugin)",
    link: AppRoute.DeveloperPlugin,
  },
  {
    title: "Создание интерактивных схем (vector-plugin)",
    link: AppRoute.VectorPlugin,
  },
  {
    title: "Создание форм (formbuilder-plugin)",
    link: AppRoute.FormBuilderPlugin,
  },
  {
    title: "Отображение данных реального времени (scada-plugin)",
    link: AppRoute.ScadaPlugin,
  },
  {
    title: "Создание интерактивных географических карт (map-plugin)",
    link: AppRoute.MapPlugin,
  },
  {
    title: "Отображение осциллограмм (oscilloscope-plugin)",
    link: AppRoute.OscilloscopePlugin,
  },
  {
    title: "Отображение видеопотока (video-plugin)",
    link: AppRoute.VideoPlugin,
  },
  {
    title: "Формирование автоматических уведомлений (message-plugin)",
    link: AppRoute.MessagePlugin,
  },
  {
    title: "Отображение статистических данных (dashboard-plugin)",
    link: AppRoute.DashboardPlugin,
  },
];

pluginsLinks.sort((a, b) => a.title.localeCompare(b.title));
